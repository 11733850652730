<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    flat
    color="#00A1B7"
    :height="mediumDevice ? 82 : 100"
  >
    <v-btn
      class="mr-3 hidden-md-and-up"
      elevation="1"
      fab
      small
      @click="
        $vuetify.breakpoint.lgAndDown
          ? setDrawer(!drawer)
          : $emit('input', !value)
      "
    >
      <!-- value is true or false -->
      <v-icon
        v-if="drawer"
        color="primary"
      >
        mdi-close
      </v-icon>

      <v-icon
        v-else
        color="primary"
      >
        mdi-menu
      </v-icon>
    </v-btn>

    <!-- <v-toolbar-title
        class="hidden-sm-and-down font-weight-light"
        v-text="$route.name"
      /> -->

    <!-- <v-text-field
        :label="$t('search')"
        color="secondary"
        hide-details
        style="max-width: 165px;"
      >
        <template
          v-if="$vuetify.breakpoint.mdAndUp"
          v-slot:append-outer
        >
          <v-btn
            class="mt-n2"
            elevation="1"
            fab
            small
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>
      </v-text-field> -->
    <logo :on-click="redirectToDash" />
    <v-row class="hidden-sm-and-down">
      <v-col cols="3">
        <v-row>
          <v-img
            :class="'pointer tablet-style logo-'+currentLang??'en'"
            height="44"
            width="155"
            contain
            src="/img/appbar-logo.png"
            @click="redirectToDash"
          />
        </v-row>
      </v-col>
      <v-col
        cols="9"
        class="hidden-sm-and-down"
      >
        <v-row class="d-flex justify-end pt-3">
          <div>
            <v-menu
              v-model="productMenuValue"
              offset-y
              origin="bottom right"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-2 btn opaque"
                  color="#FFFFFF"
                  text
                  v-bind="attrs"
                  :class="{ active: productMenuValue }"
                  v-on="on"
                >
                  {{ $t("menu.products") }}
                  <v-icon right>
                    mdi-chevron-down
                  </v-icon>
                </v-btn>
              </template>
              <!--------------PRODUCT CA MENU BAR----------------------->
              <v-list
                v-if="currentCountry=== 'CA'"
                flat
                class="product-ca-menu-list"
              >
                <v-list-item-group>
                  <v-list-item
                    v-for="(item, index) in menuitems"
                    :id="'content-menu' + index"
                    :key="index"
                    class="blanketv2accent--text px-2"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        :id="'product-ca-item-title-' + index"
                        class="product-ca-item-title"
                        v-text="$t(`menu.${toCamelCase(item.title)}`)"
                      >
                      </v-list-item-title>
                      <v-list-item-group>
                        <v-list-item
                          v-for="(subitem, subindex) in item.subitems"
                          :key="subindex"
                          class="blanketv2accent--text"
                          :to="subitem.route"
                        >
                          <v-list-item-title v-text="$t(`menu.${toCamelCase(subitem.title)}`) ">
                          </v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <!--------------PRODUCT US MENU BAR----------------------->
              <v-list v-if="currentCountry=== 'US'">
                <v-list-item
                  v-for="(item, index) in menuitems[0].subitems"
                  :key="index"
                  class="blanketv2accent--text"
                  :to="item.route"
                >
                  <v-list-item-title>
                    {{ $t(`menu.${toCamelCase(item.title)}`) }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <!-- <v-btn
              class="ml-2 btn"
              color="white"
              text
              @click="navigateToUrl"
            >
              {{ $t("menu.insights") }}
            </v-btn> -->
            <v-menu
              v-model="aboutMenuValue"
              offset-y
              origin="bottom right"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-2 btn opaque"
                  color="#FFFFFF"
                  text
                  v-bind="attrs"
                  :class="{ active: aboutMenuValue }"
                  v-on="on"
                >
                  {{ $t("menu.aboutUs") }}
                  <v-icon right>
                    mdi-chevron-down
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(item, index) in aboutItems"
                  :key="index"
                  class="blanketv2accent--text"
                  :to="item.route"
                >
                  <v-list-item-title>
                    {{ $t(toCamelCase(item.title)) }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <!-- <v-btn
                class="ml-2 btn"
                color="white"
                text
                to="/faqs"
              >
                FAQs
              </v-btn> -->
            <v-btn
              v-if="currentCountryComputed !== 'CA'"
              class="ml-2 btn"
              color="white"
              text
              to="/contactus"
            >
              {{ $t('common.contactUs') }}
            </v-btn>
            <v-btn
              v-else
              class="ml-2 btn"
              color="white"
              text
              to="/contactus-ca"
            >
              {{ $t('common.contactUs') }}
            </v-btn>
            <v-btn
              class="ml-2 btn download-app"
              color="white"
              text
              to="/download-app"
              :title="$t('downloadOurApp')"
            >
              <v-img
                src="/img/mobile_download.svg"
                width="40"
                height="30"
              >
              </v-img>
            </v-btn>

            <!--
            <v-menu
              v-model="blanketMenuValue"
              offset-y
              origin="bottom right"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-2 btn"
                  color="#FFFFFF"
                  text
                  v-bind="attrs"
                  :class="{ active: blanketMenuValue }"
                  v-on="on"
                >
                  My Blanket
                  <v-icon right>
                    mdi-chevron-down
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(item, index) in blanketItems"
                  :key="index"
                  class="blanketv2accent--text"
                  :to="item.route"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            -->
          </div>
        </v-row>

        <!-- <v-tabs>
            <v-tab
              v-for="(item, index) of menuitems"
              :key="index"
              class="ml-2 btn"
              color="primary"
              flat
              plain
              text
              :to="item.route"
            >
              {{ item.title }}
            </v-tab>
          </v-tabs> -->
      </v-col>
    </v-row>

    <!-- <v-btn
        class="ml-2"
        min-width="0"
        text
      >
        <v-icon>mdi-view-dashboard</v-icon>
      </v-btn> -->

    <!-- <v-menu
        bottom
        left
        offset-y
        origin="top right"
        transition="scale-transition"
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            class="ml-2"
            min-width="0"
            text
            v-bind="attrs"
            v-on="on"
          >
            <v-badge
              color="red"
              overlap
              bordered
            >
              <template v-slot:badge>
                <span>4</span>
              </template>
              <v-icon>mdi-bell</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <v-list
          :tile="false"
          nav
        >
          <div>
            <app-bar-item
              v-for="(n, i) in notifications"
              :key="`item-${i}`"
            >
              <v-list-item-title v-text="n" />
            </app-bar-item>
          </div>
        </v-list>
      </v-menu> -->

    <v-menu
      bottom
      left
      min-width="200"
      offset-y
      origin="bottom right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-0 opaque style-border"
          :class="mobileDevice ? 'pl-0' : ''"
          min-width="0"
          text
          v-bind="attrs"
          x-large
          v-on="on"
        >
          <v-icon
            v-if="isUserLoggedIn"
            color="white"
            x-large
          >
            mdi-account
          </v-icon>
          <v-icon
            v-if="!isUserLoggedIn"
            color="white"
            x-large
          >
            mdi-account-cancel
          </v-icon>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        flat
        nav
      >
        <div v-if="isUserLoggedIn">
          <template v-for="(p, i) in profile">
            <v-divider
              v-if="p.divider"
              :key="`divider-${i}`"
              class="mb-2 mt-2"
            />

            <app-bar-item
              v-else
              :key="`item-${i}`"
              :to="p.route"
            >
              <v-list-item-title v-text="$t(`menu.${toCamelCase(p.title)}`)" />
            </app-bar-item>
          </template>
        </div>

        <app-bar-item
          v-if="isUserLoggedIn"
          @click.native="LogUserOut"
        >
          <v-list-item-title>
            {{ $t('common.logOut') }}
          </v-list-item-title>
        </app-bar-item>

        <app-bar-item
          v-else
          @click.native="redirectToSignInPage"
        >
          <v-list-item-title>
            {{ $t('common.signIn') }}
          </v-list-item-title>
        </app-bar-item>
      </v-list>
    </v-menu>
    <v-menu
      v-if="!isTablet"
      v-model="countryMenuValue"
      close-on-click
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-0 pl-0 btn opaque mt-md-1"
          min-width="0"
          text
          v-bind="attrs"
          x-large
          :class="{ active: countryMenuValue }"
          color="#FFFFFF"
          v-on="on"
        >
          <!-- <v-icon
              color="white"
              large
            >
              mdi-web
            </v-icon> -->
          <div class="mr-3">
            <v-img
              v-if="currentCountry === 'US'"
              max-width="36"
              src="/img/app-bar/US_flag.png"
            ></v-img>
            <v-img
              v-if="currentCountry === 'CA'"
              max-width="36"
              src="/img/app-bar/canada_flag.png"
            ></v-img>
          </div>
          <span class="currentCountry">
            {{ currentCountry }}
          </span>
          <v-icon right>
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>

      <v-list class="countryMenuList">
        <app-bar-item class="px-0">
          <v-list-item @click="setWebsiteLocation('US')">
            <v-list-item-icon>
              <v-img
                max-width="36"
                src="/img/app-bar/US_flag.png"
              ></v-img>
            </v-list-item-icon>
            <v-list-item-title>
              US
            </v-list-item-title>
          </v-list-item>
        </app-bar-item>
        <app-bar-item class="px-0">
          <v-list-item @click="setWebsiteLocation('CA')">
            <v-list-item-icon>
              <v-img
                max-width="36"
                src="/img/app-bar/canada_flag.png"
              ></v-img>
            </v-list-item-icon>
            <v-list-item-title>
              CA
            </v-list-item-title>
          </v-list-item>
        </app-bar-item>
      </v-list>
    </v-menu>

    <v-menu
      v-if="!isTablet"
      v-model="languageMenuValue"
      close-on-click
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-0 pl-0 btn opaque mt-md-1"
          min-width="0"
          text
          v-bind="attrs"
          x-large
          :class="{ active: languageMenuValue, [currentCountry]: true }"
          color="#FFFFFF"
          v-on="on"
        >
          <!-- <v-icon
              color="white"
              large
            >
              mdi-tooltip-text
            </v-icon> -->
          <span class="currentLang text-uppercase">
            {{ currentLang }}
          </span>
          <v-icon right>
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>

      <v-list>
        <app-bar-item
          v-for="(languageOption, index) in filteredLanguage"
          :key="index"
          class="px-0"
        >
          <v-list-item @click="setLanguage(languageOption.code)">
            <v-list-item-title>
              {{ languageOption.label }}
            </v-list-item-title>
          </v-list-item>
        </app-bar-item>
      </v-list>
    </v-menu>
    <v-dialog
      v-if="!this.$Storage.getCookie('countryModalDisable') && currentLang!=='fr'"
      v-model="countryDialog"
      persistent
      content-class="v-dialog--custom"
    >
      <!-- <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
            Open Dialog
          </v-btn>
        </template> -->
      <v-card class="locationDialog">
        <v-card-title class="text-h3 c-modal-heading">
          {{ $t("candaModelText1") }}
        </v-card-title>
        <v-card-text>
          {{ $t("candaModelText2") }}
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn
            class="c-btn firstBtn"
            text
            @click="setWebsiteLocation('US',true)"
          >
            {{ $t("candaModelText1") }}
          </v-btn>
          <v-btn
            class="c-btn yes mint"
            text
            @click="setWebsiteLocation('CA',true)"
          >
            {{ $t("common.yes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <notify-user
      :show="showDialog"
      :lang="currentLang"
      :product-available="productAvailable"
      @cancelTrigger="handleCancel"
    >
    </notify-user>
  </v-app-bar>
</template>

<script>
// Components
  import { VHover, VListItem } from 'vuetify/lib'
  import Vue from 'vue'
  import ReveChat from '../../../../plugins/revechat'
  import Storage from '../../../../plugins/storage'
  import router from '../../../../router'
  import Logo from './Logo'
  // Utilities
  import { mapState, mapMutations } from 'vuex'
  import { localize } from 'vee-validate'
  import { loadMenu, checkCaTermPage, isProductAvailable } from '../../../../utils/common'

  import NotifyUser from '../../../../components/base/NotifyUser'
  import { toCamelCase } from '../../../../utils/normalizeToEnglish'
  import firebase from 'firebase'
  import store from '@/store/store'

  export default {
    name: 'BncAppBar',
    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(
                  VListItem,
                  {
                    attrs: this.$attrs,
                    class: {
                      'black--text': !hover,
                      'white--text primary elevation-12': hover
                    },
                    props: {
                      activeClass: '',
                      dark: hover,
                      link: true,
                      ...this.$attrs
                    }
                  },
                  this.$slots.default
                )
              }
            }
          })
        }
      },
      NotifyUser,
      Logo
    },
    props: {
      value: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      languageOptions: [
        { code: 'en', label: 'EN' },
        { code: 'fr', label: 'FR' }
      ],
      productMenuValue: null,
      blanketMenuValue: null,
      aboutMenuValue: null,
      countryMenuValue: null,
      languageMenuValue: null,
      showDialog: false,
      caTermMenu: '',
      productAvailable: false,
      aboutItems: [
        { title: 'Our Team', route: '/about/team' },
        { title: 'News', route: '/about/news' },
        { title: 'partnerships', route: '/about/partnerships' }
      ],
      menuitems: [
        {
          title: ' ',
          subitems: [
            { title: 'Main', route: '/' },
            { title: 'Auto', route: '/auto' },
            { title: 'US Term Life', route: '/afficiencyterm' },
            { title: 'Homeowners', route: '/homeowners' },
            { title: 'Term Life', route: '/termlifehome' },
            { title: 'Travel CA', route: '/travel/ca' },
            { title: 'Travel US', route: '/travel/usa' },
            // { title: 'Canadian Term', route: '/canadianterm' },
            // { title: 'Canadian Term RC', route: '/canadiantermrc' },
            { title: 'Renters', route: '/renters' },
            // { title: 'Landlord', route: '/landlord' },
            { title: 'Condo', route: '/condo' }
          // { title: 'Business', route: '/business' },
          ],
          showSubitems: 'false',
        },
        {
          title: '',
        }
      ],
      menuCountryBased: {
        US: [
          // { title: 'Main', route: '/' },
          // { title: 'Pet', route: '/pet' },
          // { title: 'Auto', route: '/auto' },
          { title: 'Term Life', route: '/afficiencyterm' },
          // { title: 'Homeowners', route: '/homeowners' },
          // { title: 'Term Life', route: '/termlifehome' },
          { title: 'Travel', route: '/travel/usa' },
          // { title: 'Canadian Term', route: '/canadianterm' },
          { title: 'Renters', route: '/renters' },
          // { title: 'Landlord', route: '/landlord' },
          { title: 'Condo', route: '/condo' }
        // { title: 'Business', route: '/business' },
        ]
      },
      blanketItems: [
        { title: 'My Profile', route: '/profile' },
        { title: 'My Policies', route: '/userpolicies' },
      ],
      profile: [{ title: 'Profile', route: '/profile' }, {
        title: 'My Applications',
        route: '/userapplications'
      }, { title: 'My Policies', route: '/userpolicies' }, { divider: true }],
      countryDialog: false,
      currentCountry: 'US',
      hasOpenApplications: false,
      currentLang: ''
    }),
    computed: {
      ...mapState(['drawer']),
      mediumDevice () {
        return this.$vuetify.breakpoint.mdOnly
      },
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      isTablet () {
        return this.$vuetify.breakpoint.smAndDown
      },
      isUserLoggedIn () {
        if (
          this.$store.getters.getuser !== null &&
          this.$store.getters.getuser !== ''
        ) {
          return true
        } else {
          return false
        }
      },
      currentCountryComputed () {
        let currentCountry = 'US'
        currentCountry = this.$store.getters.getWebsiteLocation
        return currentCountry
      },
      currentLanguageComputed () {
        return this.$Storage.getCookie('locale') || 'en'
      },
      computedCaTermMenu () {
        return loadMenu(this.$Storage.getCookie('locale'))
      },
      filteredLanguage () {
        if (this.currentCountry?.toLowerCase() === 'us') {
          return this.languageOptions.slice(0, -1)
        } else {
          return this.languageOptions
        }
      }
    },
    mounted () {
      this.currentCountry = this.setDefaultCountry() || 'US'
      this.menuitems[0].showSubitems = false
      this.menuitems[1].showSubitems = false
      this.currentLang = this.currentLanguageComputed
      this.$store.commit('setLanguage', this.currentLang)
      this.$store.dispatch('getuserlocation').then((res) => {
        const websiteLocation = this.$store.getters.getWebsiteLocation
        this.currentCountry = this.setDefaultCountry() || websiteLocation || 'US'
        this.menuitems[0].subitems = this.menuCountryBased[this.currentCountry]
        if (websiteLocation === 'CA') {
          this.countryDialog = true
        }
      })
      this.caTermMenu = this.computedCaTermMenu
      this.menuCountryBased.CA = this.caTermMenu.CA
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          store.commit('setLoggedInUser', user.email)
        } else {
          store.commit('setLoggedInUser', null)
        }
      })
    },
    methods: {
      goToApplication () {
        this.$router.push('/userapplications')
      },
      navigateToUrl () {
        window.location.href = 'http://143.198.185.59:8081/'
      },
      toCamelCase,
      ...mapMutations({
        setDrawer: 'SET_DRAWER'
      }),

      redirectToDash () {
        return router.push('/')
      },
      setDefaultCountry () {
        return this.$Storage.getCookie('location')
      },
      redirectToSignInPage () {
        this.$router.push('/pages/login')
      },
      LogUserOut () {
        // dispatch log user out
        if (window.location.pathname.includes('/canadianterm/survey')) {
          this.$router.push('/pages/login')
          localStorage.removeItem('redirectUrl')
          return
        }
        console.log(' signing out user ')
        this.$store.dispatch('signUserOut')
        this.$store.commit('setShowApplicationNotification', true)
        this.$router.push('/pages/login')
        localStorage.removeItem('redirectUrl')
      // route to login
      },
      setWebsiteLocation (location, hideModal) {
        let beforeChangeLocaiton = this.currentCountry
        this.$store.commit('setWebsiteLocation', location)
        this.$Storage.setCookie('location', location)
        if (location === 'US') {
          this.currentLang = 'en'
          this.$Storage.setCookie('locale', 'en')
          this.$i18n.locale = this.currentLang
        }
        // this.currentLang = location === 'CA' ? 'fr' : 'en'
        console.log('this.$store.getters.getWebsiteLocation')
        console.log(this.$store.getters.getWebsiteLocation)
        this.countryDialog = false
        this.currentCountry = this.$store.getters.getWebsiteLocation
        this.menuitems[0].subitems = this.menuCountryBased[this.currentCountry]
        this.$store.commit('setWebsiteLocation', location)
        console.log(this.menuitems[0].subitems)
        if (beforeChangeLocaiton !== location && this.$route.path !== '/') {
          console.log('got to homepage')
          this.$router.push('/')
        }
        if (hideModal && location === 'CA') {
          this.$Storage.setCookie('countryModalDisable', true)
        }
      },
      async setLanguage (languageCode) {
        this.currentLang = languageCode
        this.$store.commit('setLanguage', languageCode)
        if (checkCaTermPage()) {
          await this.$store.dispatch('updatelang', { languageCode })
          this.showDialog = true
        }
        if (!isProductAvailable() && this.currentLang === 'fr') {
          await this.$store.dispatch('updatelang', { languageCode })
          this.showDialog = true
          this.productAvailable = true
        }
        if (isProductAvailable() && !checkCaTermPage() && this.$i18n.locale !== this.currentLang) {
          await this.$store.dispatch('updatelang', { languageCode })
          this.$Storage.setCookie('locale', this.$store.getters.getLanguage)
          location.reload()
          localize(languageCode)
        }
      },
      handleCancel () {
        const updateLang = this.currentLang === 'en' ? 'fr' : 'en'
        this.currentLang = updateLang
        this.$store.commit('setLanguage', updateLang)
        this.showDialog = false
      }
    },
  }
</script>
<style>
.v-dialog--custom {
  max-width: 450px;
}

@media screen and (max-width: 600px) {
  .v-dialog--custom {
    max-width: 275px;
  }

  .v-dialog--custom .v-card__actions {
    display: block;
    text-align: center;
  }

  .v-dialog--custom .firstBtn {
    margin-bottom: 1rem;
  }
}
</style>
<style lang="scss" scoped>
a {
  text-decoration: none;
}

.product-ca-menu-list .v-list-item__title.product-ca-item-title {
  white-space: pre-line;
}

.btn {
  text-transform: none;
  font-size: 18px;
}

@media screen and (max-width: 1070px) {
  .btn {
    font-size: 14px;
  }
}

.v-btn.active .v-icon {
  transform: rotate(-180deg);
}

.v-btn::before {
  display: none;
}

.v-btn:hover {
  opacity: 0.8;
}

a {
  text-decoration: none;
}

.pointer:hover {
  cursor: pointer;
}

.currentCountry,
.currentLang {
  color: white;
  width: 23px;
  text-align: center;
}

.locationDialog {
  border-top: 3px solid #00a1b7;
}

.c-btn {
  color: #00a1b7 !important;
  font-weight: bold !important;
}

.c-btn.yes {
  color: #292f36 !important;
}

.v-dialog {
  border-radius: 0px !important;
}

.c-modal-heading {
  text-align: left !important;
  font-weight: bold !important;
}

.countryMenuList .v-list-item__icon {
  margin-right: 16px !important;
}

.product-ca-menu-list {
  width: 205px;
}

.product-ca-item-title {
  font-size: 16px;
  font-weight: 400;
  padding-left: 17px;
  position: relative;
  top: -10px;
}

#product-ca-item-title-0 {
  display: none;
}

.v-list-item__title, .v-list-item__subtitle {
  white-space: pre-wrap;
}

#content-menu1 {
  display: none;
}

.style-border:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

.logo-img-fr {
  max-height: 38px !important;
  position: relative;
  top: -6px;
  display: none;
}

@media screen and (max-width: 900px) {
  .logo-img-fr {
    display: block !important;
  }
}

.CA {
  display: block;
}

.US {
  display: none;
}

@media (min-width: 800px) and (max-width: 1355px) {
    header {
      height: auto!important;
      min-height: 91px;
    }
    .row.hidden-sm-and-down>div>div {
       height: 100%;
    }
    .tablet-style  {
       height: auto!important;
    }
    ::v-deep .v-image__image--contain {
      width: 70%;
    }
  }
  @media screen and (min-width: 1200px) {
    ::v-deep .v-btn:not(.v-btn--round).v-size--x-large {
        padding: 0 10px!important;
    }
    .download-app {
      position: relative;
      left: 5px;
      top: -3px;
    }
  }
</style>
